import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import covenantsDoc from '../docs/Declaration_of_Covenants.pdf'
import bylawsDoc from '../docs/Elkhorn_Highland_Ridge_Homeowners_Association_Bylaws_9-20-2023.pdf' 

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Alert
} from "reactstrap";

import OtherPageHeader from "../components/otherPageHeader.js";

const CovenantPage = () => {
  const [alert, setAlert] = React.useState(true);

  return (
    <>
      <Layout pageTitle="Covenants">
        <OtherPageHeader />
        {/* <Alert className="alert-with-icon home-alert" color="info" isOpen={alert}>
          <Container>
            <div className="alert-wrapper">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => setAlert(false)}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <div className="message">
                <i className="nc-icon nc-bell-55" /> <b>Stay tuned!</b>  HOA Bylaws coming soon!
              </div>
            </div>
          </Container>
        </Alert> */}
        <div className="main">
          <div className="section">
            <Container className="text-center">
              <Row>
                <Col>
                  <h2 className="text-primary">Elkhorn Highland Ridge Covenants and Bylaws</h2>
                  
                  <p className="lead">Open document to view searchable PDF.</p>
                  <hr className="my-2 mx-5" />
                  <br />
                  <Button
                    className="h5"
                    href={covenantsDoc}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="link"
                  >
                  <i className="nc-icon nc-minimal-right" /> Declaration_of_Covenants.pdf
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="h5"
                    href={bylawsDoc}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="link"
                  >
                  <i className="nc-icon nc-minimal-right" /> Elkhorn_Highland_Ridge_Homeowners_Association_Bylaws_9-20-2023.pdf
                  </Button>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <Button
                    href={covenantsDoc}
                    key="covs"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="link"
                  >Declaration_of_Covenants.pdf</Button>
                  {/* <a href={covenantsDoc} download>Download the directly imported file</a>
                  {/* <a href={`Declaration_of_Covenants.pdf`} download>Download the file that is already in your static folder</a>
                </Col>
              </Row> */}
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CovenantPage
